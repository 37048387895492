u {
    color: blue;
}

.maxWidth100 {
    max-width: 100%;
}

@media (min-width: 10px) {

    .articleImg {
        width: 40px;
    }
}

@media (min-width: 500px) {

    .articleImg {
        width: 60px;
    }
}

@media (min-width: 992px) {

    .articleImg {
        width: 120px;
    }
}




.maxHeight100 {
    height: 50vh;
}

.natureBackground {
    background-image: url('/images/navbar-background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.homeBackCover {
    font-size: large;
    color: white;
    text-align: center;
    height: 100%;
    display: flex;
    place-content: center;
    align-items: center;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}


.cursor-pointer {
    cursor: pointer;
}

.w-10 {
    width: 10%;
}

.dropdown {
    position: relative;
    /* display: inline-block; */
}

.dropdown-content {
    /* display: none; */
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
}

.bg-dark>span {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}