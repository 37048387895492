@import '../css/app.css';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Hebrew:wght@500&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");